/* You can add global styles to this file, and also import other style files */

.card-table {
  margin: calc(-16 * calc((1rem / 20 ) * 1 ) );
  
  .table {
    border-left: none;
    border-right: none;
    border-radius: 0;
    margin-top: 0;
    border-top: none;
    border-bottom: none;
  }

  .datagrid {
    margin-top: 0;
  }
}

.app-loading {
  text-align: center;

  .spinner {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
  }
}


.connectivity {
  border-radius: 100%;
  height: 0.6em;
  width: 0.6em;
  display: inline-block;
}

.connectivity.online {
  background-color: rgb(60, 133, 0);
}

.connectivity.offline {
  background-color: rgb(219, 33, 0);
}

.connectivity.unknown {
  background-color: rgb(102, 102, 102);
}

.header-actions {
  .dropdown {
    height: 100%;

    .dropdown-toggle {
      height: 100%;
    }
  }
} 
